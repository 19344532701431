import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        width: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: 400,
    },
    searchBoxContainerWide: {
        maxWidth: "400px",
        width: "100%",
        height: 48,
        border: theme.searchBox?.open?.border ?? "none",
        borderRadius: theme.searchBox?.open?.borderRadius ?? "0px",
        paddingLeft: "8px",
        paddingRight: 8,
        [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            paddingLeft: "10px",
            width: "100%",
        },
    },
    searchIcon: {
        width: "24px",
        height: "24px",
        marginRight: 8,
        "&:hover": {
            cursor: "pointer",
        },
    },
    placeholderPrompt: {
        color: theme.searchBox?.placeholderColor ?? "#323537",
        backgroundColor: theme.searchBox?.backgroundColor,
    },
    input: {
        textAlign: "start",
        width: "100%",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        color: theme.searchBox?.inputColor ?? "#323537",
        backgroundColor: theme.searchBox?.backgroundColor,
        "&::-ms-clear": {
            display: "none",
            width: 0,
            height: 0,
        },
        fontFamily: theme.typography.fontFamily,
        "&::-webkit-search-cancel-button": {
            "-webkit-appearance": "none",
        },
        "&::placeholder": {
            color: theme.searchBox?.placeholderColor ?? "#323537",
            opacity: 1,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "4px",
        },
    },
}));
